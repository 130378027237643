<template>
    <div>
        <v-autocomplete
            v-model="value"
            :items="items"
            dense
            hint="Type to filter"
            persistent-hint
            class="my-4"
            label="Your institution (full name)"
            no-data-text="No match - consider choosing 'Other'"
        ></v-autocomplete>
        <div class="text-center">
            <v-btn v-if="value !== '' && value !== 'Other'" block @click="loginWithInstitution" color="secondary">University login</v-btn>
        </div>
        <slot v-if="value !== ''"></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: '',
            items: [
                'Other',
                'BFH - Berner Fachhochschule',
                'CERN',
                'Careum',
                'DistanceUniversity',
                'EPFL - EPF Lausanne',
                'ETH Zurich',
                'Eawag',
                'Empa',
                'Euresearch Head Office',
                'FHGR - Fachhochschule Graubünden',
                'FHNW - Fachhochschule Nordwestschweiz',
                'FMI - Friedrich Miescher Institute',
                'FTL - Facoltà di Teologia di Lugano',
                'HEP Vaud - Haute école pédagogique du canton de Vaud',
                'HEP-BEJUNE - Haute Ecole Pédagogique BEJUNE',
                'HEP-PH FR - University of Teacher Education Fribourg',
                'HEPVS - Haute école pédagogique du Valais',
                'HES-SO - Haute École Spécialisée de Suisse occidentale',
                'HSLU - Lucerne University of Applied Sciences and Arts',
                'HSR - Hochschule für Technik Rapperswil',
                'HfH - Interkantonale Hochschule für Heilpädagogik',
                'Idiap Research Institute',
                'Insel Gruppe',
                'NTB - Hochschule für Technik Buchs',
                'OST - Ostschweizer Fachhochschule',
                'PH Zug - Pädagogische Hochschule Zug',
                'PH Zürich - Pädagogische Hochschule Zürich',
                'PHBern - Pädagogische Hochschule Bern',
                'PHGR - University of Teacher Education Graubünden',
                'PHLU - University of Teacher Education Lucerne',
                'PHSG - Pädagogische Hochschule St.Gallen',
                'PHSZ - Pädagogische Hochschule Schwyz',
                'PHTG - Pädagogische Hochschule Thurgau',
                'PMOD/WRC - Observatory Davos',
                'PSI - Paul Scherrer Institut',
                'SFIVET - Swiss Federal Institute for Vocational Education and Training',
                'SNSF - Swiss National Science Foundation',
                'SUPSI - Scuola Universitaria Professionale della Svizzera Italiana',
                'University of Fribourg',
                'Università della Svizzera italiana',
                'Universität Basel',
                'Universität Bern',
                'Universität Liechtenstein',
                'Universität Luzern',
                'Universität St. Gallen',
                'Universität Zürich',
                'Universitätsklinik Balgrist',
                'Université de Genève',
                'Université de Lausanne',
                'Université de Neuchâtel',
                'WSL - Swiss Federal Institute for Forest, Snow and Landscape Research',
                'ZHAW - Zürcher Hochschule für Angewandte Wissenschaften',
                'ZHdK - Zürcher Hochschule der Künste',
                'Zentral- und Hochschulbibliothek Luzern'
            ]
        }
    },
    props: {
        redirectTo: {
            type: String,
            default: location.pathname
        }
    },
    methods: {
        loginWithInstitution() {
            if (this.$data.value !== 'Other') {
                this.$auth.login({ customState: { target: this.$props.redirectTo }, connection: 'SATOSA' })
            }
        }
    }
}
</script>
