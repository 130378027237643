var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "my-4",
        attrs: {
          items: _vm.items,
          dense: "",
          hint: "Type to filter",
          "persistent-hint": "",
          label: "Your institution (full name)",
          "no-data-text": "No match - consider choosing 'Other'"
        },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.value !== "" && _vm.value !== "Other"
            ? _c(
                "v-btn",
                {
                  attrs: { block: "", color: "secondary" },
                  on: { click: _vm.loginWithInstitution }
                },
                [_vm._v("University login")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.value !== "" ? _vm._t("default") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }